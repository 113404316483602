import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './index.css';
import Home from './pages/home/home';
import Notfound from './pages/notfound/notfound';
import Footer from './components/footer/footer';
import Header from './components/header/header'
import Apropos from './pages/apropos/apropos'
import Contact from './pages/contact/contact';
import Portfolio from './pages/portfolio/portfolio';

 
ReactDOM.render(
    <React.StrictMode>

        <Router>
            <Header />
            
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<Apropos />} />
                <Route path="/portfolio" element={<Portfolio />}/>
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<Notfound/>}/>
            </Routes>
            <Footer />
        </Router>
    </React.StrictMode>,
document.getElementById('root')
);



