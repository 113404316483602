import { Link } from 'react-router-dom';
import './blochome.css';



function BlocHome(props) {
  const e = props.e

  console.log(e.image);


    return (  
      <div className='blochome' id={e.style}>
        <div className='bloctext'>
          <h1>{e.title}</h1>
          <h2>{e.subtitle}</h2>
          <p>{e.description}</p>
          <a href='/portfolio' alt={e.title}>Portfolio</a>
        </div>
        <Link to="/" className='linkimg'>
          <img src={e.image} alt={e.title}/>
        </Link>
       
    </div>
    )
} 


export default BlocHome;