import './apropos.css'


function Apropos() {

    return <content id="a-propos">
            

        </content>
        
}

export default Apropos;