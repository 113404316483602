import './footer.css'
import logoW from '../header/logo-w.svg'

function Footer () {
    return <footer>
        <img src={logoW} alt="logo Footer"></img>
            <span>© 2023 Jib's. All rights reserved</span>
        </footer>
    
}
export default Footer;