import logoB from './logo-b.svg';
import './header.css';
import { NavLink} from 'react-router-dom';

//Créer les routes !!

function Header() {
    return (  
    <header>

      <NavLink to="/"><img src={logoB} alt='logo jibs'/></NavLink>
      <div id="wrap">
        
        <nav>
          <NavLink to="/">accueil</NavLink>
          <NavLink to="/portfolio">portfolio</NavLink>
          <NavLink to="/contact">contact</NavLink>
        </nav>
      </div>
    </header>
    )
} 
//récupération et soulignement du lien avec la classe "active"
var currentPageURL = window.location.href;
var navLinks = document.querySelectorAll('.nav-link');
navLinks.forEach(function(link) {
  if (link.href === currentPageURL) {
    link.classList.add('active');
  }
});


export default Header