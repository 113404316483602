//import logo from './logo.svg';
import './home.css';
import BlocHome from '../../components/blochome/blochome';
import datas from '../../datas/homedatas.json'

console.log(datas);

function Home() {
  return (
    <content>
      <section>
      {datas.map((e)=> (
            
             <BlocHome key={e.id} e={e} />
            ))}
      </section>

    </content>
  );
}


export default Home;
